import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";
import { FaArrowLeft } from "react-icons/fa";

const Stp = () => {
  const { clsc, pb, amount, currency } = useParams();
  const stripePromise = loadStripe(pb);

  return (
    <>
      <div className="w-max flex gap-4 items-center mt-8 p-2 h-max">
        <Link to={"/home"} className="bg-red-500 p-2 rounded-md">
          <FaArrowLeft className="cursor-pointer" fill="#fff" />
        </Link>
        <h1 className="font-bold text-gray-500">Pay with Debit / Credit</h1>
      </div>
      <div className="flex flex-col sm:flex-row items-center min-h-[10vh] sm:min-h-[60vh] justify-normal sm:justify-center">
        <div className="left-col h-full mb-4 hidden w-[50%] sm:flex items-center justify-center">
          <div>
            <img
              src="/assets/stripe.png"
              alt="stripe"
              className="w-[75%] rounded"
            />
          </div>
        </div>
        <div className="right-col py-4 sm:py-0 w-full sm:w-[50%] h-[40vh] shadow-lg">
          <Elements stripe={stripePromise}>
            <CheckoutForm amount={amount} currency={currency} clsc={clsc} />
          </Elements>
          <div className="flex flex-wrap justify-center sm:justify-start items-center mt-[1.5rem] sm:mt-0 x">
            <p className="text-xs mt-0 order-1 xl:order-2 text-start w-max">
              Powered by
              <span className="text-[#635BFF] font-bold ms-1">Stripe</span>
            </p>
            <div className="flex flex-wrap sm:flex-nowrap w-full  justify-center   sm:justify-end gap-2 order-2 xl:order-1">
              <img
                src="/assets/union-pay.png"
                className="w-[35px] sm:w-[50px]"
              />
              <img src="/assets/visa.png" className="w-[35px] sm:w-[50px]" />
              <img
                src="/assets/mastercard.png"
                className="w-[35px] sm:w-[50px]"
              />
              <img
                src="/assets/american-express.png"
                className="w-[35px] sm:w-[50px]"
              />
              <img src="/assets/ucb.png" className="w-[35px] sm:w-[50px]" />
              <img
                src="/assets/discover.png"
                className="w-[35px] sm:w-[50px]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stp;
