import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { app_url } from "../config";

const Otp = ({
  otpRecieved,
  apiUrl,
  changeTabOnOtpVerification,
  iccid,
  emptyUserDetailsAfterOtpVerification,
  otpVerified,
  response,
  onClose,
  countryCode,
  email,
  firstname,
  lastname,
  mobileNo,
  city,
  country,
  state,
  packageId,
  quantity,
  existingPackageId
}) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const token = JSON.parse(localStorage.getItem("worldslocalesim-agent"))?.token;

  const handleOtpChange = (newOtp) => {
    // You can add validation or other logic here if needed
    setOtp(newOtp);
  };

  function verifyOtp() {
    setIsLoading(true);
    if (
      !country ||
      !countryCode ||
      !email ||
      !lastname ||
      !firstname ||
      !mobileNo ||
      !city ||
      !state ||
      !otp ||
      otp.length < 6
    ) {
      toast.error("OTP required!");
      setIsLoading(false);
      return;
    }

    axios
      .post(
        `${app_url}/api/customer/${apiUrl}`,
        {
          first_name: firstname,
          last_name: lastname,
          email: email,
          mobile: `${countryCode}${mobileNo}`,
          city: city,
          state: state,
          country: country,
          eId: packageId ? packageId : null,
          packageId: existingPackageId ? existingPackageId : null,
          otp: otp,
          quantity: quantity ? quantity : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message);
        onClose();
        changeTabOnOtpVerification();
        otpVerified();
        emptyUserDetailsAfterOtpVerification();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data.message);
          setIsLoading(false);
        }
        // toast.error(err.response?.data.message)
      });
  }

  return (
    <div className={`otp-input-container ${response ? "block" : "hidden"}`}>
      {/* You can style the container div as needed */}

      <p className="font-bold text-sm mb-3">
        *Please Enter the OTP your customer has recieved on his email.
      </p>
      <OTPInput
        value={otp}
        onChange={handleOtpChange}
        inputType="tel"
        renderInput={(props) => (
          <input
            {...props}
            className="otp-input bg-gray-400 rounded-md text-center text-white font-semibold"
            style={{ maxWidth: "35px", height: "35px" }}
          />
        )}
        numInputs={6}
        renderSeparator={
          <span className="otp-separator"> &nbsp;&nbsp;&nbsp;&nbsp; </span>
        }
      />
      <button
        onClick={verifyOtp}
        disabled={isLoading}
        className="px-4 py-2 bg-green-600 rounded-lg text-white mt-4"
      >
        {isLoading ? "...loading" : "Register Customer"}
      </button>
    </div>
  );
};

export default Otp;
