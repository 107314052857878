import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { app_url } from "../config";
import toast from "react-hot-toast";
const CustomerPdf = ({ cs }) => {
  const navigate = useNavigate();
  const [bulkData, setBulkData] = useState([]);
  const [msg400, setMsg400] = useState("");
  const [error400, setError400] = useState(false);
  const [online, setOnline] = useState(navigator.onLine);
  const token = JSON.parse(
    localStorage.getItem("worldslocalesim-agent")
  )?.token;

  Font.register({
    family: "Roboto",
    src: "/fonts/Roboto-Bold.ttf",
  });

  const styles = StyleSheet.create({
    sectionOne: {
      marginVertical: 30,
      marginHorizontal: 30,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    One: {
      padding: "1em",
      width: "100px",
    },
    bold: {
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: 14,
    },
    text: {
      fontSize: 9,
      marginVertical: 4,
      wordBreak: "break-all",
    },
  });

  useEffect(() => {
    if (cs.includes("GetBulk")) {
      const extract = cs.split("GetBulk");
      const id = extract[1];
      axios
        .get(`${app_url}/api/line/getAllLinesByCustomerId?customerId=${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setBulkData(res.data.esims);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            navigate("/");
          } else if (err.response?.status === 400) {
            setError400(true);
            setMsg400(err.response.data.message);
          } else {
            // setIsLoading(false);
            setOnline(navigator.onLine);
          }
        });
    } else if ("getSingle") {
      const extract = cs.split("getSingle");
      const id = extract[1];

      axios
        .get(`${app_url}/api/line/getLineByLineId?lineId=${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          setBulkData(res.data.esims);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            navigate("/");
          } else if (err.response?.status === 400) {
            setError400(true);
            setMsg400(err.response.data.message);
            toast.error(err.response.data.message);
          } else {
            setOnline(navigator.onLine);
          }
        });
    }
  }, []);

  // const insertSoftHyphens = (text, maxLength) => {
  //   const segments = [];
  //   for (let i = 0; i < text.length; i += maxLength) {
  //     segments.push(text.substring(i, i + maxLength));
  //   }
  //   return segments.join("\H"); // Soft hyphen character
  // };

  return (
    <div className="pdf w-full">
      <button
        onClick={() => {
          navigate("/myCustomers");
        }}
        className="shadow rounded mt-5 flex gap-1 items-center px-2 py-2 bg-primary text-white border-0"
      >
        <IoArrowBack size={20} /> <span>Go Back</span>
      </button>
      <PDFViewer
        showToolbar
        width={"100%"}
        height={"750px"}
        style={{ marginTop: "1em" }}
      >
        <Document>
          {bulkData?.map(
            (
              {
                packageName,
                apnValue,
                apnType,
                qrCodeUrl,
                systemOrderId,
                iccid,
                activationCode,
              },
              index
            ) => (
              <Page key={index} size="A4">
                <View style={styles.sectionOne}>
                  <View>
                    <Image src={"/assets/WLS.png"} style={styles.One} />
                  </View>
                  <View>
                    <Text>Confidential</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <View
                      style={{
                        marginHorizontal: 30,

                        fontWeight: "bold",
                      }}
                    >
                      <Text style={styles.bold}>Order#</Text>
                      <Text
                        style={{
                          fontSize: 14,
                          marginVertical: 4,
                        }}
                      >
                        {systemOrderId}
                      </Text>
                    </View>
                    <View style={{ marginHorizontal: 30, marginVertical: 10 }}>
                      <Text style={styles.bold}>Package Name</Text>
                      <Text style={{ fontSize: 14, marginVertical: 4 }}>
                        {packageName}
                      </Text>
                    </View>
                    <View style={{ marginHorizontal: 30, marginVertical: 10 }}>
                      <Text style={styles.bold}>ICCID</Text>
                      <Text style={{ fontSize: 14, marginVertical: 4 }}>
                        {iccid}
                      </Text>
                    </View>
                    <View style={{ marginHorizontal: 30, marginVertical: 10 }}>
                      <Text style={styles.bold}>Apn type</Text>
                      <Text style={{ fontSize: 14, marginVertical: 4 }}>
                        {apnType}
                      </Text>
                    </View>
                    <View style={{ marginHorizontal: 30, marginVertical: 10 }}>
                      <Text style={styles.bold}>Apn value</Text>
                      <Text style={{ marginVertical: 4, fontSize: 14 }}>
                        {apnValue === null ? "--" : apnValue}
                      </Text>
                    </View>
                    {/* <View style={{ marginHorizontal: 30, marginVertical: 10 }}>
                      <Text style={styles.bold}>Activation Code</Text>
                      <Text style={styles.text}>
                        {activationCode === null ? "--" : activationCode}
                      
                      </Text>
                    </View> */}
                  </View>
                  <View style={{ marginHorizontal: 30 }}>
                    <Image src={qrCodeUrl} style={{ width: "150px" }} />
                    <Text
                      style={{ marginTop: 10, marginLeft: 20, fontSize: 15 }}
                    >
                      Scan QR-CODE
                    </Text>
                  </View>
                </View>
                <View style={{ marginHorizontal: 30, marginVertical: 10 }}>
                  <Text style={styles.bold}>Activation Code</Text>
                  <Text style={styles.text}>
                    {activationCode === null ? "--" : activationCode}
                  </Text>
                </View>
                <View style={{ marginHorizontal: 30 }}>
                  <Text style={{ marginVertical: 10, fontWeight: 900 }}>
                    Steps to Install your World's local eSIM
                  </Text>
                  <View>
                    <View>
                      <View>
                        <Text style={{ fontSize: "10px", marginVertical: 6 }}>
                          1. Once your reach your destination country, Enable
                          the installed eSIM.
                        </Text>
                        <Text style={{ fontSize: "10px", marginVertical: 6 }}>
                          2. Make sure your device supports eSIM.
                        </Text>
                        <Text style={{ fontSize: "10px", marginVertical: 6 }}>
                          3. Make Sure your device is connected to the Internet
                          (WiFi).
                        </Text>
                        <Text style={{ fontSize: "10px", marginVertical: 6 }}>
                          4. Go to your Device setting and select Sim Manager.
                        </Text>
                        <Text style={{ fontSize: "10px", marginVertical: 6 }}>
                          5. Select + Add eSIM.
                        </Text>
                        <Text style={{ fontSize: "10px", marginVertical: 6 }}>
                          6. Scan the QR Above.
                        </Text>
                      </View>
                    </View>
                  </View>
                  <Text style={{ marginVertical: 10, fontWeight: 900 }}>
                    Important Notes
                  </Text>
                  <View>
                    <View>
                      <View>
                        <Text style={{ fontSize: "10px", marginVertical: 6 }}>
                          1. Your data SIM card selection is important to be
                          selected as eSIM before you enable the Data Roaming.
                        </Text>
                        <Text style={{ fontSize: "10px", marginVertical: 6 }}>
                          2. The exact steps may vary depending on your device's
                          make and model. It's essential to follow your device
                          manufacturer if there is a special step required to
                          activate the eSIM
                        </Text>
                        <Text
                          style={{
                            fontSize: "10px",
                            marginVertical: 6,
                            lineHeight: 1,
                          }}
                        >
                          3. The QR code can only be used once. You may Top-Up
                          to the same package to use it again without deleting
                          your eSIM but only enable or disable.
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </Page>
            )
          )}
        </Document>
      </PDFViewer>
    </div>
  );
};

export default CustomerPdf;
